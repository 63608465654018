import AuthService from '@/auth/auth-service';

export default {
  name: 'auth-callback',
  async mounted() {
    try {
      const { appState } = await AuthService.handleRedirectCallback();
      var redirectPath = appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname;

      if (redirectPath.indexOf('http://') > -1 || redirectPath.indexOf('https://') > -1) {
        window.location.href = redirectPath;
      } else {
        this.$router.push(redirectPath);
      }
    } catch (e) {
      this.$log.error(e);
      this.$router.push({ name: 'forbidden' });
    }
  }
};
